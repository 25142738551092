import React from 'react'
import Player from './player/player'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Footer from './footer'
import Body from './body'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { createContext } from 'react'
import DesktopVersionSuggestion from './suggestion/desktopVersionSuggestion'
import PaymentStatus from './payment/paymentStatus'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { ErrorBoundary } from './shared/errorBoundary'
import VideoUpload from './videoUpload'
import Contact from './contact'
import ScriptSuggestion from './suggestion/scriptSuggestion'
import ThankYou from './payment/thankYou'
import { PaymentStatusEnum, Product } from './shared/utils'
import FixedContact from './fixedContact'
import GlobalStyle from './styles/globalStyle'
import { Alert, Snackbar } from '@mui/material'
import Privacy from './privacy'
import Terms from './terms'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '')

const client = new ApolloClient({
	uri: process.env.REACT_APP_APOLLO_CLIENT_URL,
	cache: new InMemoryCache(),
})

export const UserContext = createContext({
	userFirstName: '',
	setUserFirstName: (e: string) => {},
	userLastName: '',
	setUserLastName: (e: string) => {},
	userEmail: '',
	setUserEmail: (e: string) => {},
})

export const CheckoutContext = createContext({
	product: Product.MobileVersion,
	setProduct: (e: Product) => {},
	desktopSpecialOfferOver: false,
	setDesktopSpecialOfferOver: (e: boolean) => {},
	customerId: '',
	setCustomerId: (e: string) => {},
	clientSecret: '',
	setClientSecret: (e: string) => {},
	paymentStatus: PaymentStatusEnum.Undefined,
	setPaymentStatus: (e: PaymentStatusEnum) => {},
})

const App = () => {
	const [userEmail, setUserEmail] = React.useState('')
	const [userFirstName, setUserFirstName] = React.useState('')
	const [userLastName, setUserLastName] = React.useState('')
	const [product, setProduct] = React.useState(Product.MobileVersion)
	const [desktopSpecialOfferOver, setDesktopSpecialOfferOver] =
		React.useState(false)
	const [customerId, setCustomerId] = React.useState('')
	const [clientSecret, setClientSecret] = React.useState<string>('')
	const [paymentStatus, setPaymentStatus] = React.useState<PaymentStatusEnum>(
		PaymentStatusEnum.Undefined
	)

	const userContextValue = {
		userEmail,
		setUserEmail,
		userFirstName,
		setUserFirstName,
		userLastName,
		setUserLastName,
	}
	const checkoutContextValue = {
		product,
		setProduct,
		desktopSpecialOfferOver,
		setDesktopSpecialOfferOver,
		customerId,
		setCustomerId,
		clientSecret,
		setClientSecret,
		paymentStatus,
		setPaymentStatus,
	}

	React.useEffect(() => {
		if (paymentStatus !== PaymentStatusEnum.Undefined) {
			setPaymentStatus(PaymentStatusEnum.Undefined)
		}
	}, [])

	return (
		<ErrorBoundary>
			<ApolloProvider client={client}>
				<BrowserRouter>
					<div
						className=''
						style={{
							width: '1170px',
							maxWidth: '100%',
							marginRight: 'auto',
							marginLeft: 'auto',
						}}
					>
						<UserContext.Provider value={userContextValue}>
							<CheckoutContext.Provider value={checkoutContextValue}>
								{/* <Navigation /> */}
								<Routes>
									<Route path='/' element={<Body />} />
									<Route path='/tutorials/:id' element={<Player />} />
									<Route path='/contact' element={<Contact />} />
									<Route
										path='/desktop-version-offer'
										element={<DesktopVersionSuggestion />}
									/>
									<Route
										path='/script-suggestion'
										element={<ScriptSuggestion />}
									/>
									<Route
										path='/thank-you'
										element={
											<Elements stripe={stripePromise}>
												<ThankYou />
											</Elements>
										}
									/>
									<Route path='/video-upload' element={<VideoUpload />} />
									<Route
										path='/payment-status'
										element={
											<Elements stripe={stripePromise}>
												<PaymentStatus />
											</Elements>
										}
									/>
									<Route path='/privacy' element={<Privacy />} />
									<Route path='/terms' element={<Terms />} />
								</Routes>
								<FixedContact />
								<GlobalStyle />
								<Footer />
							</CheckoutContext.Provider>
						</UserContext.Provider>
					</div>
				</BrowserRouter>
			</ApolloProvider>
		</ErrorBoundary>
	)
}

export default App
