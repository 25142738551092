import React from 'react'
import './css/fixedContact.css'
import CloseIcon from '@mui/icons-material/Close'
import ForumIcon from '@mui/icons-material/Forum'
import { useMutation } from '@apollo/client'
import { createContactGql } from './mutation/createContact'
import { Alert, Snackbar } from '@mui/material'
import { isValidEmail } from './shared/utils'
import Button from './shared/button'

function FixedContact() {
	const [isActive, setActive] = React.useState(false)
	const [name, setName] = React.useState<string>('')
	const [email, setEmail] = React.useState<string>('')
	const [message, setMessage] = React.useState('')
	const [noValidEmail, setNoValidEmail] = React.useState<boolean>(false)
	const [isSubmitted, setIsSubmitted] = React.useState(false)
	const [createContact, { loading, error }] = useMutation(createContactGql, {
		onCompleted: () => {
			setIsSubmitted(true)
		},
	})
	function chatToggleHandler() {
		setActive(!isActive)
	}
	function reset() {
		setName('')
		setEmail('')
		setMessage('')
		setNoValidEmail(false)
		setIsSubmitted(false)
	}
	function onNameChange(e: HTMLInputElement) {
		setName(e.value)
	}

	function onEmailChange(e: HTMLInputElement) {
		if (e.value.match(isValidEmail)) {
			setNoValidEmail(false)
		} else {
			setNoValidEmail(true)
		}
		setEmail(e.value)
	}

	function hanleOnMessageChange(e: HTMLTextAreaElement) {
		setMessage(e.value)
	}
	if (!!error) {
		throw new Error(
			`Error while creating contact: ${error.message}. Please, reach out to admin.`
		)
	}
	const isButtonDisabled = !name || !email || noValidEmail || isSubmitted
	return (
		<div className='formbold-main-wrapper'>
			<Snackbar
				onClose={reset}
				open={isSubmitted}
				autoHideDuration={5000}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Alert severity='success' sx={{ width: '100%' }}>
					Your message successully sent!
				</Alert>
			</Snackbar>
			<div className={`formbold-form-wrapper ${isActive ? 'active' : null}`}>
				<div className='formbold-form-header'>
					<h3>Need Help?</h3>
					<button className='closeIcon' onClick={chatToggleHandler}>
						<CloseIcon />
					</button>
				</div>
				<div className='formbold-chatbox-form'>
					<div className='formbold-mb-5'>
						<label className='formbold-form-label'> Your Name </label>
						<input
							type='text'
							name='name'
							id='name'
							placeholder='Your Name'
							className='formbold-form-input'
							value={name}
							onChange={({ currentTarget }) => onNameChange(currentTarget)}
						/>
						{!name && (
							<h3
								style={{
									margin: '5px 0',
									color: 'red',
									textAlign: 'left',
								}}
							>
								Required
							</h3>
						)}
					</div>

					<div className='formbold-mb-5'>
						<label className='formbold-form-label'> Email Address </label>
						<input
							type='email'
							name='email'
							id='email'
							placeholder='example@domain.com'
							className='formbold-form-input'
							value={email}
							onChange={({ currentTarget }) => onEmailChange(currentTarget)}
						/>
						{!email && (
							<h3
								style={{
									margin: '5px 0',
									color: 'red',
									textAlign: 'left',
								}}
							>
								Required
							</h3>
						)}
						{!!email && noValidEmail && (
							<h3
								style={{
									margin: '5px 0',
									color: 'red',
									textAlign: 'left',
								}}
							>
								Provide a valid email
							</h3>
						)}
					</div>

					<div className='formbold-mb-5'>
						<label className='formbold-form-label'> Message </label>
						<textarea
							rows={4}
							name='message'
							id='message'
							placeholder='Message'
							className='formbold-form-input'
							value={message}
							onChange={({ currentTarget }) =>
								hanleOnMessageChange(currentTarget)
							}
						></textarea>
						{message === '' && (
							<h3
								style={{
									margin: '5px 0',
									color: 'red',
									textAlign: 'left',
								}}
							>
								Required
							</h3>
						)}
					</div>

					<div>
						<Button
							className={'formbold-btn w-full custom-button'}
							text='Submit'
							loading={loading}
							disabled={loading || isButtonDisabled}
							onClick={async () => {
								await createContact({
									variables: {
										name: name.trim(),
										email: email.trim().toLowerCase(),
										message: message,
									},
								})
							}}
						/>
					</div>
				</div>
			</div>
			<div className='formbold-action-buttons'>
				<button
					className={`formbold-action-btn ${isActive ? 'active' : null}`}
					onClick={chatToggleHandler}
				>
					<span className='formbold-cross-icon'>
						<CloseIcon />
					</span>
					<span className='formbold-chat-icon'>
						<ForumIcon />
					</span>
				</button>
			</div>
		</div>
	)
}

export default FixedContact
