import React from 'react'
import './css/footer.css'
import EmailIcon from '@mui/icons-material/Email'
import InstagramIcon from '@mui/icons-material/Instagram'
import { useNavigate } from 'react-router'

const Footer: React.FC = (props) => {
	const navigate = useNavigate()
	return (
		<div className='footer-container'>
			<div className='footer-wrapper'>
				<div className='all-right-reserve-container'>
					BinaryBridges - © <span>{new Date().getFullYear()}</span> All Rights
					Reserve | Los Angeles, California 90006
				</div>
				<div className='term-container'>
					<a target='_blank' href={`${process.env.REACT_APP_HOST}/terms`}>
						Terms
					</a>
					{' - '}
					<a target='_blank' href={`${process.env.REACT_APP_HOST}/privacy`}>
						Privacy
					</a>
				</div>
				<div className='email-container'>
					<EmailIcon
						style={{
							marginRight: '15px',
						}}
					/>
					<span>binarybridgeonline@gmail.com</span>
				</div>
				<div className='insta-container'>
					<InstagramIcon
						style={{
							marginRight: '15px',
						}}
					/>
					<a
						target='_blank'
						href='https://www.instagram.com/0.binary.bridges.1/'
					>
						0.binary.bridges.1
					</a>
				</div>
			</div>
		</div>
	)
}

export default Footer
