import React from 'react'
import Button from '../shared/button'
import mobile4 from '../assets/images/mobile4.png'
import desktop from '../assets/images/desktop.png'
import upgrade from '../assets/images/upgrade.png'
import '../css/thankYou.css'
import {
	getPrice,
	PaymentStatusEnum,
	Product,
	productMapper,
} from '../shared/utils'
import { Alert, Snackbar } from '@mui/material'
import Receipt from './receipt'
import { CheckoutContext, UserContext } from '../App'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import CelebrationIcon from '@mui/icons-material/Celebration'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { paymentIntent } from './payment-util'
import WarningIcon from '@mui/icons-material/Warning'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import { useNavigate, useNavigationType } from 'react-router'
import Verification from './verification'

function getImage(product: Product) {
	if (product === Product.DesktopVersionAndScript) {
		return upgrade
	}
	if (product === Product.DesktopVersion) {
		return desktop
	}
	return mobile4
}

function getTitle(product: Product, paymentStatus?: PaymentStatusEnum) {
	if (product === Product.MobileVersion) {
		return (
			<div className='title'>
				<ThumbUpAltIcon
					className='thank-you-title-icon'
					color='warning'
					style={{
						width: '70px',
						height: '70px',
					}}
				/>
				<h3>
					We've Just Sent The Product To Your Email! Be Sure To Check Spam
					Folder...
				</h3>
			</div>
		)
	}
	if (paymentStatus === PaymentStatusEnum.Undefined) {
		return (
			<div className='title'>
				<ShoppingCartIcon
					className='thank-you-title-icon'
					color='warning'
					style={{
						width: '70px',
						height: '70px',
					}}
				/>
				<h3>Click Check Out Button Below Now!</h3>
			</div>
		)
	}
	if (paymentStatus === PaymentStatusEnum.Succeed) {
		return (
			<div className='title'>
				<CelebrationIcon
					className='thank-you-title-icon'
					color='warning'
					style={{
						width: '70px',
						height: '70px',
					}}
				/>
				<h3>
					Congrats! Successfully Purchased. We've Sent it to Email. <br />
					Please Check Your Email Including Spam Folder...
				</h3>
			</div>
		)
	}

	if (paymentStatus === PaymentStatusEnum.Processing) {
		return (
			<div className='title'>
				<ShoppingCartIcon
					className='thank-you-title-icon'
					color='warning'
					style={{
						width: '70px',
						height: '70px',
					}}
				/>
				<h3>
					We Are Processing Your Order. Please, Do Not Click Back Button...
				</h3>
			</div>
		)
	}

	if (paymentStatus === PaymentStatusEnum.Require_Other_Payment) {
		return (
			<div className='title'>
				<WarningIcon
					className='thank-you-title-icon'
					color='warning'
					style={{
						width: '70px',
						height: '70px',
					}}
				/>
				<h3>
					Sorry, We Couldn't Process The Order. Your Card Is <u>Invalid</u>.
					Please, Use Other Payments...
				</h3>
			</div>
		)
	}
	return (
		<div className='title'>
			<WarningIcon
				className='thank-you-title-icon'
				color='warning'
				style={{
					width: '70px',
					height: '70px',
				}}
			/>
			<h3>
				Sorry, There Was <u>Unexpected Error</u> during processing the order.
				Please Try Again...
			</h3>
		</div>
	)
}

function ThankYou() {
	const navigate = useNavigate()
	const stripe = useStripe()
	const elements = useElements()
	const [isToastOpen, setIsToastOpen] = React.useState(false)
	const { userEmail, userFirstName, userLastName } =
		React.useContext(UserContext)
	const { product, desktopSpecialOfferOver, paymentStatus, setPaymentStatus } =
		React.useContext(CheckoutContext)
	const [isCheckedOut, setIsCheckedOut] = React.useState(false)
	const [isProductDownloading, setIsProductDownloading] =
		React.useState<boolean>(false)
	const [message, setMessage] = React.useState<string>('')
	const thankYouHeaderRef = React.useRef<null | HTMLDivElement>(null)
	const [error, setError] = React.useState<string>()
	const paymentIntentClientSecret = React.useRef('')

	React.useEffect(() => {
		// UserContext value gets cleared after refresh,
		// then navigate user to the main page
		if (!userEmail || !userFirstName || !userLastName) {
			navigate('/')
		}
	}, [])

	React.useEffect(() => {
		if (!stripe || !elements) {
			return
		}
		if (isCheckedOut && !!paymentIntentClientSecret.current) {
			stripe
				.retrievePaymentIntent(paymentIntentClientSecret.current)
				.then(({ paymentIntent }: any) => {
					switch (paymentIntent.status) {
						case 'succeeded':
							// redirect to success page
							setPaymentStatus(PaymentStatusEnum.Succeed)
							sendToEmail(userEmail)
							break

						case 'processing':
							setPaymentStatus(PaymentStatusEnum.Processing)
							break

						case 'requires_payment_method':
							// Redirect your user back to your payment page to attempt collecting
							// payment again
							setPaymentStatus(PaymentStatusEnum.Require_Other_Payment)
							break

						default:
							setPaymentStatus(PaymentStatusEnum.Other)
							break
					}
				})
				.catch((e) => {
					setError(`Error while retrieving payment intent: ${e}`)
				})
		}
	}, [isCheckedOut])

	const sendToEmail = async (userEmail: string) => {
		try {
			fetch(`${process.env.REACT_APP_EXPRESS_SERVER_URL}/send-to-email`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: userEmail,
					product: productMapper[product],
					desktopSpecialOfferOver: desktopSpecialOfferOver,
				}),
			}).then(() => {
				setIsToastOpen(true)
			})
		} catch (e: any) {
			setError(`sendToEmail error: ${e.message}.`)
		}
	}

	const onProductDownload = async () => {
		try {
			setIsProductDownloading(true)
			window.location.href = `${process.env.REACT_APP_EXPRESS_SERVER_URL}/download/${productMapper[product]}`
		} catch (e: any) {
			setError(
				`Error while downloading docx file: ${e.message}. Please, reach out to admin.`
			)
		} finally {
			setIsProductDownloading(false)
		}
	}

	if (error) {
		throw new Error(error)
	}

	return (
		<div className='thank-you-container'>
			<Snackbar
				onClose={() => {
					setIsToastOpen(false)
				}}
				open={isToastOpen}
				autoHideDuration={2000}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Alert severity='success' sx={{ width: '100%' }}>
					We've also sent the script to your email!
				</Alert>
			</Snackbar>
			<div className='congrats-container' ref={thankYouHeaderRef}>
				{getTitle(product, paymentStatus)}
				<h2>
					{paymentStatus === PaymentStatusEnum.Succeed &&
						'Also, The Download Link Is Ready For You Below...'}
				</h2>
			</div>
			<div className='product-receipt-container'>
				<div className='image-container'>
					<img className='thank-you-image' src={getImage(product)} />
				</div>
				{(product === Product.MobileVersion ||
					paymentStatus === PaymentStatusEnum.Succeed) && (
					<div className='download-button-wrapper'>
						<Button
							className={'custom-button'}
							text='DOWNLOAD'
							loading={isProductDownloading}
							onClick={onProductDownload}
						/>
					</div>
				)}
				<div className='receipt-checkout-container'>
					<Receipt
						userFirstName={userFirstName}
						userLastName={userLastName}
						userEmail={userEmail}
						product={product}
						isCheckedOut={paymentStatus === PaymentStatusEnum.Succeed}
					/>
					<div className='verification-wrapper'>
						<Verification
							setMessage={setMessage}
							setIsCheckedOut={setIsCheckedOut}
							paymentIntentClientSecret={paymentIntentClientSecret}
							thankYouHeaderRef={thankYouHeaderRef}
						/>
					</div>
				</div>

				<div className='receipt-wrapper'>
					{!!message && (
						<h1
							style={{
								color: 'red',
								marginTop: '20px',
								fontSize: '500',
							}}
						>
							{message}
						</h1>
					)}
				</div>
			</div>
		</div>
	)
}

export default ThankYou
