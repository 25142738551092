import React from 'react'
import { CheckoutContext } from '../App'
import '../css/receipt.css'
import { getPrice, Product } from '../shared/utils'

const productMap: { [key in Product]: string } = {
	[Product.MobileVersion]: 'YouTube Mobile Clone',
	[Product.DesktopVersion]: 'YouTube Desktop Clone',
	[Product.DesktopVersionAndScript]:
		'Youtube Desktop Clone and Behavior Questions Script',
}

function sharedInfo() {
	return (
		<>
			<tr>
				<td>1</td>
				<td>YouTube Mobile Clone</td>
				<td>$1</td>
			</tr>
			<tr>
				<td>1</td>
				<td>Refund - YouTube Mobile Clone</td>
				<td>$-1</td>
			</tr>
		</>
	)
}

function getItem(product: Product, desktopSpecialOfferOver: boolean) {
	if (product === Product.MobileVersion) {
		return sharedInfo()
	}
	if (product === Product.DesktopVersion) {
		if (desktopSpecialOfferOver) {
			return (
				<>
					{sharedInfo()}
					<tr>
						<td>1</td>
						<td>{productMap[product]}</td>
						<td>$30</td>
					</tr>
				</>
			)
		}
		return (
			<>
				{sharedInfo()}
				<tr>
					<td>1</td>
					<td>{productMap[product]}</td>
					<td>$10</td>
				</tr>
			</>
		)
	}
	// fall here only if add a script into a cart
	if (desktopSpecialOfferOver) {
		return (
			<>
				{sharedInfo()}
				<tr>
					<td>1</td>
					<td>{productMap[Product.DesktopVersion]}</td>
					<td>$30</td>
				</tr>
				<tr>
					<td>1</td>
					<td>{productMap[product]}</td>
					<td>$24.99</td>
				</tr>
			</>
		)
	}
	return (
		<>
			{sharedInfo()}
			<tr>
				<td>1</td>
				<td>{productMap[Product.DesktopVersion]}</td>
				<td>$10</td>
			</tr>
			<tr>
				<td>1</td>
				<td>{productMap[product]}</td>
				<td>$24.99</td>
			</tr>
		</>
	)
}

function Receipt({
	userFirstName,
	userLastName,
	userEmail,
	product,
	isCheckedOut,
}: {
	userFirstName: string
	userLastName: string
	userEmail: string
	product: Product
	isCheckedOut: boolean
}) {
	const dateTime = new Date()
	const { desktopSpecialOfferOver } = React.useContext(CheckoutContext)

	return (
		<div className='receipt-container'>
			<div className='receipt_header'>
				<h1>{isCheckedOut ? 'Receipt' : 'Cart'}</h1>
				<h2>Binary Bridges</h2>
				<div className='user-info'>
					<h2>{`Hi, ${userFirstName} ${userLastName}`}</h2>
					{isCheckedOut ? (
						<>
							<h4>{"We've also sent the receipt to"}</h4>
							<h3>{userEmail}</h3>
						</>
					) : (
						<h4>{'Here Are Your Life Changers!'}</h4>
					)}
				</div>
			</div>

			<div className='receipt_body'>
				<div className='date_time_con'>
					<div className='date'>{dateTime.toLocaleDateString()}</div>
					<div className='time'>{dateTime.toLocaleTimeString() + ' PDT'}</div>
				</div>
				<div className='items'>
					<table>
						<thead>
							<tr>
								<th>QTY</th>
								<th>ITEM</th>
								<th>AMT</th>
							</tr>
						</thead>
						<tbody>{getItem(product, desktopSpecialOfferOver)}</tbody>
						<tfoot>
							<tr>
								<td>Total</td>
								<td></td>
								<td>{`$${getPrice(product, desktopSpecialOfferOver)}`}</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
	)
}

export default Receipt
