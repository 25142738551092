import React, { CSSProperties } from 'react'
import CircularProgress from '@mui/joy/CircularProgress'
import '../css/button.css'

type ButtonProps = {
	onClick: () => void
	text: string
	loading: boolean
	disabled?: boolean
	icon?: React.ReactNode
	style?: CSSProperties
	className: string
}

function Button(props: ButtonProps) {
	const { onClick, text, loading, disabled, icon, className, style } = props
	return (
		<button
			style={style}
			className={className}
			onClick={onClick}
			disabled={disabled || loading}
		>
			{!loading ? (
				<>
					{icon}
					{text}
				</>
			) : (
				<CircularProgress size='sm' color='neutral' variant='soft' />
			)}
		</button>
	)
}

export default Button
