import React from 'react'
import script from '../assets/images/script.png'
import '../css/scriptSuggestion.css'
import PanToolIcon from '@mui/icons-material/PanTool'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useNavigate } from 'react-router'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import { CheckoutContext, UserContext } from '../App'
import { PaymentStatusEnum, Product } from '../shared/utils'

function ScriptSuggestion() {
	const navigate = useNavigate()
	const ref = React.useRef<null | HTMLDivElement>(null)
	const { userEmail, userFirstName, userLastName } =
		React.useContext(UserContext)
	const { setProduct, paymentStatus, setPaymentStatus } =
		React.useContext(CheckoutContext)

	React.useEffect(() => {
		ref?.current?.scrollIntoView({ behavior: 'smooth' })
		if (paymentStatus !== PaymentStatusEnum.Undefined) {
			navigate('/')
			setPaymentStatus(PaymentStatusEnum.Undefined)
		}
		// when UserContext gets cleared after refresh
		if (!userEmail || !userFirstName || !userLastName) {
			navigate('/')
		}
	}, [])

	return (
		<div className='script-suggestion-container'>
			<div className='header' ref={ref}>
				<div className='inner'>
					<div className='icon'>
						<h1>
							WAIT{' '}
							<PanToolIcon
								className='panToolIcon'
								color='warning'
								style={{
									display: 'inline-block',
									width: '70px',
									height: '70px',
									marginRight: '30px',
								}}
							/>{' '}
							LAST CHANCE!
						</h1>
					</div>
					<div>
						<h1 className='warning-sub'>
							You're only going to see this page <u>ONCE</u> (ever),
						</h1>
						<h1 className='warning-sub'>
							so please <u>READ</u> every word very carefully. It's really that
							important...
						</h1>
						<h2 className='upgrade-text'>
							With Your Upgraded Resume Possibly Landing You Interviews...
							<u>ARE YOU PREPARED?</u>
						</h2>
					</div>
				</div>
			</div>
			<div className='content'>
				<div className='content-inner'>
					<div className='image-what-you-get-container'>
						<div className='image-container'>
							<div className='what-you-get'>
								This special offer is for those who got the <u>DESKTOP</u>{' '}
								version, believing it opens doors for you.
							</div>
							<img src={script} alt='script' />
						</div>
					</div>
					<div className='script-info-container'>
						<div>
							<div className='bullet-point'>
								<li></li>
								<span>
									<b>Insider Secrets Revealed:</b> Understand what interviewers
									are looking for
								</span>
							</div>
							<div className='bullet-point'>
								<li></li>
								<span>
									<b>Customized Answers:</b> Tailored using the STAR technique
									to highlight your skills.
								</span>
							</div>
							<div className='bullet-point'>
								<li></li>
								<span>
									<b>Boost Your Confidence:</b> Prepared answers make you a more
									assured candidate.
								</span>
							</div>
							<div className='bullet-point'>
								<li></li>
								<span>
									<b>Tackle Tough Questions:</b> Transform difficult queries
									into moments to excel.
								</span>
							</div>
							<div className='bullet-point'>
								<li></li>
								<span>
									<b>Stand Out:</b> Distinct responses that differentiate you
									from the competition.
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className='click-below-button'>
					<KeyboardDoubleArrowDownIcon
						style={{
							fontSize: '40px',
							marginRight: '10px',
							color: 'black',
						}}
					/>
					<b>
						Click the button below right now to add the script to your order.
					</b>
				</div>
			</div>
			<div className='script-suggestion-footer-wrapper'>
				<div className='script-suggestion-footer-inner'>
					<div className='question-container'>
						<div className='context'>
							Let's look at a{' '}
							<b>
								<u>5th</u> most common one...
							</b>
						</div>
						<div className='question'>
							"Describe a project where you quickly learned new technology.{' '}
							<br /> How did you gain the skills?"
						</div>
						<div className='context'>
							Did you answer{' '}
							<b>
								<u>WITHOUT</u>
							</b>{' '}
							hesitation?
						</div>
						<div className='third-question'>
							Imagine if this 5th famous question came up in an <u>ACTUAL</u>{' '}
							interview and you could <u>NOT</u> answer it..
						</div>
					</div>
					<div className='yes-container'>
						<button
							onClick={() => {
								navigate('/thank-you')
								setProduct(Product.DesktopVersionAndScript)
							}}
						>
							<ArrowForwardIcon
								style={{
									fontSize: '40px',
									marginRight: '10px',
								}}
							/>
							Yes! ADD "This Interview Cracking Script" to my order NOW!
						</button>
					</div>
					<div className='additonal-info-container'>
						<p>
							<u>YES! Accept This Amazing Offer Now!</u>
						</p>
						<h2>
							<span className='was-text'>Was $40</span> Just $24.99!
						</h2>
						<h1>
							This <span className='special-offer-text'>Special Offer</span>{' '}
							will disappear once you leave this page...
						</h1>
					</div>
					<div className='no-container'>
						<p style={{ margin: '40px' }}>
							<u
								onClick={() => {
									setProduct(Product.DesktopVersion)
									navigate('/thank-you')
								}}
								style={{ cursor: 'pointer' }}
							>
								No Thanks, I Don't Want Life-Changing Script - I'll Pass At This
								Time
							</u>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ScriptSuggestion
