export const createCustomer = async <T>(
    firstName: string,
    lastName: string, 
    email: string
  )
  : Promise<any> => {
    const res = await fetch(`${process.env.REACT_APP_EXPRESS_SERVER_URL}/create-customer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName:lastName,
        email:email
      })
    });

    return await res.json();
  }  
  
  export const paymentIntent = async <T>(
    amount: number,
    customerId: string,
    paymentMethodId: string
  )
  : Promise<string> => {
    const res = await fetch(`${process.env.REACT_APP_EXPRESS_SERVER_URL}/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        amount: amount,
        customerId:customerId,
        paymentMethodId:paymentMethodId
      })
    });

    const { clientSecret } = await res.json();
    return clientSecret;
  }

   export const setupIntent = async <T>(customerId: string)
  : Promise<any> => {
    const res = await fetch(`${process.env.REACT_APP_EXPRESS_SERVER_URL}/create-setup-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        customerId: customerId
      })
    });

    return await res.json();
  }