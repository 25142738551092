export const isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g

export enum Product {
  MobileVersion = 'MOBILE_VERSION',
  DesktopVersion = 'DESKTOP_VERSION',
  DesktopVersionAndScript = 'DESKTOP_VERSION_SCRIPT'
}

export enum PaymentStatusEnum {
	Undefined = 'UNDEFINED',
	Succeed = 'SUCCEED',
	Processing = 'PROCESSING',
	Require_Other_Payment = 'REQUIRE_OTHER_PAYMENT',
	Other = 'OTHER',
}

export const productMapper: { [key in Product]: string } = {
	[Product.MobileVersion]: 'MobileVersion',
	[Product.DesktopVersion]: 'DesktopVersion',
	[Product.DesktopVersionAndScript]: 'DesktopVersionAndScript',
}

export interface UserInfoFields {
	firstName: string
	lastName: string
	email: string
}

export function getPrice(product: Product, desktopSpecialOfferOver: boolean) {
	if (product === Product.MobileVersion) {
		return 0
	}
	if (product === Product.DesktopVersion) {
		if (desktopSpecialOfferOver) {
			return 30
		}
		return 10
	}
	// fall here only if add a script into a cart
	if (desktopSpecialOfferOver) {
		return 54.99
	}
	return 34.99
}