import React from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Button from '../shared/button'
import { CheckoutContext, UserContext } from '../App'
import { useNavigate } from 'react-router'
import { getPrice, PaymentStatusEnum } from '../shared/utils'
import { paymentIntent } from './payment-util'
import { StripePaymentElement } from '@stripe/stripe-js'

function ScriptPaymentForm({
	clientSecret,
	setMessage,
	paymentIntentClientSecret,
	setIsCheckedOut,
	thankYouHeaderRef,
}: {
	clientSecret: string
	setMessage: (val: string) => void
	paymentIntentClientSecret: React.MutableRefObject<string>
	setIsCheckedOut: (val: boolean) => void
	thankYouHeaderRef: React.MutableRefObject<HTMLDivElement | null>
}) {
	const stripe = useStripe()
	const elements = useElements()
	const [isCardInfoFilled, setIsCardInfoFilled] = React.useState(false)
	const [isProcessingPayment, setIsProcessingPayment] =
		React.useState<boolean>(false)

	const { customerId, product, desktopSpecialOfferOver, setPaymentStatus } =
		React.useContext(CheckoutContext)
	const { userEmail } = React.useContext(UserContext)
	const [error, setError] = React.useState<string>()
	const priceToCheckout = Number(
		(getPrice(product, desktopSpecialOfferOver) * 100).toFixed()
	)

	const [paymentElementRef, setPaymentElementRef] =
		React.useState<StripePaymentElement>()

	const confirmSetup = async () => {
		if (!userEmail) {
			setError('Please, provide your email address in the first tab.')
		}
		if (!stripe || !elements) {
			setError('Stripe or elements is null. Please, contact to admin.')
			return
		}

		try {
			// Trigger form validation and wallet collection
			const { error: submitError } = await elements.submit()
			if (submitError) {
				setError(`Error while calling submit: ${submitError}`)
			}
			const { setupIntent, error: confirmError } = await stripe.confirmSetup({
				elements: elements,
				clientSecret: clientSecret,
				redirect: 'if_required',
			})
			if (confirmError) {
				setError(`Error while calling confirmSetup: ${submitError}`)
				return
			}
			return (setupIntent?.payment_method as string) || ''
		} catch (e: any) {
			setError(`Error while calling confirmSetup: ${e.message}`)
		}
	}

	const checkoutHandler = async (paymentMethodId?: string) => {
		// here paymentMethodId is undefined..
		if (!stripe || !elements || !paymentMethodId) {
			setError(
				'Stripe or elements or paymentMethodId is null. Please, contact to admin.'
			)
			return
		}
		try {
			paymentIntentClientSecret.current = await paymentIntent(
				priceToCheckout,
				customerId,
				paymentMethodId
			)
			const { error } = await stripe.confirmPayment({
				clientSecret: paymentIntentClientSecret.current,
				redirect: 'if_required',
			})
			if (!error) {
				setIsCheckedOut(true)
				thankYouHeaderRef?.current?.scrollIntoView({ behavior: 'smooth' })
			}
			if (error) {
				// This point will only be reached if there is an immediate error when
				// confirming the payment. Show error to your customer (for example, payment
				// details incomplete)
				setMessage(error.message || '')
				setPaymentStatus(PaymentStatusEnum.Other)
				setError(error.message)
			} else {
				// Your customer will be redirected to your `return_url`. For some payment
				// methods like iDEAL, your customer will be redirected to an intermediate
				// site first to authorize the payment, then redirected to the `return_url`.
			}
		} catch (e: any) {
			setError(`paymentIntent error: ${e.message}`)
		}
	}

	if (error) {
		throw new Error(error)
	}

	return (
		<div>
			<PaymentElement
				onReady={(e) => setPaymentElementRef(e)}
				onChange={(e) => setIsCardInfoFilled(e.complete)}
			/>
			<div>
				<Button
					className={'verification-button custom-button'}
					onClick={async () => {
						setIsProcessingPayment(true)
						const paymentMethodId = await confirmSetup()
						await checkoutHandler(paymentMethodId)
						paymentElementRef?.clear()
						setError(undefined)
						setIsProcessingPayment(false)
					}}
					loading={isProcessingPayment}
					text={'CHECK OUT'}
					icon={
						<ArrowForwardIcon
							style={{
								fontSize: '40px',
								marginRight: '10px',
							}}
						/>
					}
					disabled={!clientSecret || !isCardInfoFilled || !!error}
				/>
			</div>
		</div>
	)
}

export default ScriptPaymentForm
