import React from 'react'
import './css/body.css'
import Subscription from './subscription/subscription'
import Button from './shared/button'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import StarIcon from '@mui/icons-material/Star'
import StarHalfIcon from '@mui/icons-material/StarHalf'

const responsive = {
	desktop: {
		breakpoint: {
			max: 3000,
			min: 1024,
		},
		items: 3,
		partialVisibilityGutter: 40,
	},
	mobile: {
		breakpoint: {
			max: 464,
			min: 0,
		},
		items: 1,
		partialVisibilityGutter: 30,
	},
	tablet: {
		breakpoint: {
			max: 1024,
			min: 464,
		},
		items: 2,
		partialVisibilityGutter: 30,
	},
}

function Body() {
	const ref = React.useRef<null | HTMLDivElement>(null)
	return (
		<div className='body-container'>
			<div className='body-header'>
				<h1>Change Your Life!</h1>
				<h2>
					"Secrets of being software engineer that most competitors are
					completely unaware of.."
				</h2>
			</div>
			<Subscription scrollTo={ref} />
			<div className='body-content'>
				<Carousel
					additionalTransfrom={0}
					arrows
					autoPlaySpeed={10000}
					centerMode={false}
					className='carousel-container'
					containerClass='container-with-dots'
					dotListClass=''
					draggable
					focusOnSelect={false}
					infinite
					itemClass=''
					keyBoardControl
					minimumTouchDrag={80}
					autoPlay
					pauseOnHover
					renderArrowsWhenDisabled={false}
					renderButtonGroupOutside={false}
					renderDotsOutside={false}
					responsive={responsive}
					rewind={false}
					rewindWithAnimation={false}
					rtl={false}
					shouldResetAutoplay
					showDots={false}
					sliderClass=''
					slidesToSlide={1}
					swipeable
				>
					<div className='carousel-item'>
						<p>
							Amazing Prices.. The rewards,
							starting with the Mobile YouTube Clone, are huge!
						</p>
						<div className='rating'>
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
						</div>
						<p className='reviwer-name'>-Jake M</p>
					</div>
					<div className='carousel-item'>
						<p>
							The quality of the Mobile YouTube Clone is outstanding. It's been
							invaluable for my projects.
						</p>
						<div className='rating'>
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
						</div>
						<p className='reviwer-name'>-Emma R</p>
					</div>
					<div className='carousel-item'>
						<p>
							The clone’s code was a lifesaver when I hit a roadblock with my
							app’s functionality. Cleared it right up!
						</p>
						<div className='rating'>
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
						</div>
						<p className='reviwer-name'>-Ava S</p>
					</div>
					<div className='carousel-item'>
						<p>
							I faced persistent issues with responsive design until I used the
							Mobile YouTube Clone. It guided me through perfectly!
						</p>
						<div className='rating'>
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
						</div>
						<p className='reviwer-name'>-Oliver T</p>
					</div>
					<div className='carousel-item'>
						<p>
							Provided my name and email, got the Mobile Clone, and couldn't
							resist the desktop version on the upsell page. Each step has been
							worth it!
						</p>
						<div className='rating'>
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
						</div>
						<p className='reviwer-name'>-Mia P</p>
					</div>
					<div className='carousel-item'>
						<p>
							The Mobile YouTube Clone exceeded my expectations, prompting me to
							buy the Desktop version and the interview question scripts, both
							of which have been invaluable.
						</p>
						<div className='rating'>
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
						</div>
						<p className='reviwer-name'>-Noah D</p>
					</div>
					<div className='carousel-item'>
						<p>
							The free Mobile Clone impressed me so much that I went for the
							Desktop version and later the interview questions. Each product
							has been crucial in my career development
						</p>
						<div className='rating'>
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarHalfIcon color='warning' />
						</div>
						<p className='reviwer-name'>-Isabella G</p>
					</div>
					<div className='carousel-item'>
						<p>
							OMG, after providing name and email, I received an amazing Mobile
							YouTube Clone. I didn't hesitate to buy the desktop version!
						</p>
						<div className='rating'>
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarHalfIcon color='warning' />
						</div>
						<p className='reviwer-name'>-Liam F</p>
					</div>
					<div className='carousel-item'>
						<p>
							Thrilled with the Mobile YouTube Clone! It’s a fantastic resource
							for any aspiring developer.
						</p>
						<div className='rating'>
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
						</div>
						<p className='reviwer-name'>-Sophia Q</p>
					</div>
					<div className='carousel-item'>
						<p>
							The Mobile Clone was excellent, leading me straight to the Desktop
							version. I didn’t stop there; the behavioral questions script was
							too good to pass up and really helped in interviews.
						</p>
						<div className='rating'>
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
							<StarIcon color='warning' />
						</div>
						<p className='reviwer-name'>-Lucas H</p>
					</div>
				</Carousel>
				<div style={{ display: 'inline-block' }}>
					Want To Know How I Made My Salary From <u>ZERO to $200,000</u> As a
					Software Engineer...? Then, Get Your <b>FREE Mobile Youtube Clone</b>{' '}
					Now!
				</div>
				<div className='body-box-wrapper'>
					<div className='body-box-container'>
						<div className='body-box-title'>
							<h1 className='main-title'>
								Boost Your Career with Binary Bridges
							</h1>
							<h1 className='sub-title'>
								Achieve Your Dream Job with Our Exclusive Offer
							</h1>
						</div>
						<div className='body-box-content'>
							Dear Friends,
							<div>
								<br />
							</div>
							<div>Hello, I am Kay, the founder of Binary Bridges.</div>
							<div>
								<br />
							</div>
							<div>
								Every year in the United States,{' '}
								<b>
									more than 100,000 students graduate from computer-related
									majors.
								</b>
							</div>
							<div>
								<br />
							</div>
							<div>
								Many of these students are <b>UNABLE</b> to find a job due to
								not having a substantial record of experience...
							</div>
							<div>
								<br />
							</div>
							<div>
								Today, I want to offer you the <b>OPPORTUNITY</b> to add a
								significant entry to your resume for <b>FREE</b>. Use this
								opportunity to get closer to be employed!
							</div>
							<div>
								<br />
							</div>
							<div>
								I understand that your resume may <b>NOT</b> stand out to
								recruiters as much as you would like and may not be
								competitive...
							</div>
							<div>
								<br />
							</div>
							<div>
								People <b>SPEND</b> hundreds of dollars to take courses and
								learn programming languages and framework skills,
							</div>
							<div>
								<br />
							</div>
							<div>
								but most of these skills do <b>NOT</b> stand out or
								realistically add a significant entry to your resume...
							</div>
							<div>
								<br />
							</div>
							<div>
								This is why I am offering you a significant <b>FREE</b> entry .
							</div>
							<div>
								<br />
							</div>
							<div>
								By using this opportunity, you can attract recruiters to your
								resume and create a substantial opportunity for employment
								without spending hundreds of dollars.
							</div>
							<div>
								<br />
							</div>
							<div>
								A single <b>FREE</b> entry can create opportunity for you.
								Download it now!
							</div>
							<div>
								<br />
							</div>
							<div>Click the link below.</div>
							<div className='body-box-container'>
								<Button
									className={'free-mobile-download-button custom-button'}
									onClick={() =>
										ref?.current?.scrollIntoView({ behavior: 'smooth' })
									}
									text='Download Mobile YouTube Clone FREE NOW!'
									loading={false}
								/>
							</div>
							<div>
								<br />
							</div>
							<div>
								You might be wondering why I am offering this <b>FREE</b> entry.
							</div>
							<div>
								<br />
							</div>
							<div>
								The reason is, if this <b>FREE</b> entry catches the eye of
								recruiters and leads to more interview requests for you,
							</div>
							<div>
								<br />
							</div>
							<div>
								you won't forget Binary Bridges, and then I can have you as a
								lifetime customer.
							</div>
							<div>
								<br />
							</div>
							<div>
								This week, those who receive the free entry will get another{' '}
								<b>FREE</b> CSS Style Guide Documentation as a bonus.
							</div>
							<div>
								<br />
							</div>
							<div>
								It will be a useful addition to understand the how UI gets
								styled.
							</div>
							<div>
								<br />
							</div>
							<div>
								<b>REMEMBER!</b>
							</div>
							<div>
								<br />
							</div>
							<div>
								If you register today, this additional bonus is <b>FREE</b>.
							</div>
							<div>
								<br />
							</div>
							<div>
								You can still receive the <b>FREE</b> entry after that, but you
								won't be able to receive the free additional bonus.
							</div>
							<div>
								<br />
							</div>
							<div>
								If for any reason you decide not to continue with Binary
								Bridges, please let us know immediately.
							</div>
							<div>
								<br />
							</div>
							<div>
								Your account will be canceled right away, and there will be no
								hassle.
							</div>
							<div>
								<br />
							</div>
							<div>
								However, if you still want to stay with Binary Bridges, you can
								simply remain a member!
							</div>
							<div>
								<br />
							</div>
							<div>
								Click the link below to receive an amazing entry to your resume
								for <b>FREE</b>
								<div className='body-box-container'>
									<Button
										className={'free-mobile-download-button custom-button'}
										onClick={() =>
											ref?.current?.scrollIntoView({ behavior: 'smooth' })
										}
										text='Download Mobile YouTube Clone FREE NOW!'
										loading={false}
									/>
								</div>
							</div>
							<div>
								<br />
							</div>
							<div>
								Don't forget, if you create an account today, the additional
								bonus is <b>FREE</b>!
							</div>
							<div>
								<br />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Body
