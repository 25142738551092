import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import ScriptPaymentForm from './scriptPaymentForm'
import '../css/scriptCheckout.css'
import '../css/verification.css'
import { createCustomer, setupIntent } from './payment-util'
import Skeleton from '@mui/material/Skeleton'
import { CheckoutContext, UserContext } from '../App'
import ssl1 from '../assets/images/ssl1.png'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '')

function Verification({
	setMessage,
	setIsCheckedOut,
	paymentIntentClientSecret,
	thankYouHeaderRef,
}: {
	setMessage: (val: string) => void
	setIsCheckedOut: (val: boolean) => void
	paymentIntentClientSecret: React.MutableRefObject<string>
	thankYouHeaderRef: React.MutableRefObject<HTMLDivElement | null>
}) {
	const { userEmail, userFirstName, userLastName } =
		React.useContext(UserContext)
	const { clientSecret } = React.useContext(CheckoutContext)

	return (
		<div className='verification-container'>
			<div>
				<img src={ssl1} alt='ssl' />
			</div>
			{!clientSecret && (!userFirstName || !userLastName || !userEmail) && (
				<h1>Please, Fill Out User Information First.</h1>
			)}
			{!clientSecret && !!userFirstName && !!userLastName && !!userEmail && (
				<>
					<Skeleton animation='pulse' height={30} />
					<Skeleton animation='wave' height={30} />
					<Skeleton animation='pulse' height={30} />
					<Skeleton animation='wave' height={30} />
					<Skeleton animation='pulse' height={30} />
					<Skeleton animation='wave' height={30} />
				</>
			)}
			{clientSecret && (
				<Elements
					stripe={stripePromise}
					options={{
						clientSecret: clientSecret,
					}}
				>
					<ScriptPaymentForm
						clientSecret={clientSecret}
						setMessage={setMessage}
						setIsCheckedOut={setIsCheckedOut}
						paymentIntentClientSecret={paymentIntentClientSecret}
						thankYouHeaderRef={thankYouHeaderRef}
					/>
				</Elements>
			)}
		</div>
	)
}

export default Verification
