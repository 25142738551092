import React from 'react'
import mobile from '../assets/images/mobile4.png'
import rightArrow from '../assets/images/fast-forward.gif'
import '../css/subscription.css'
import { useMutation } from '@apollo/client'
import { startSubscribeGql } from '../mutation/startSubscribe'

import StartSubscribe from './startSubscribe'
import {
	UserInfoFields,
	isValidEmail,
	productMapper,
	Product,
} from '../shared/utils'
import Verification from '../payment/verification'
import LooksOneIcon from '@mui/icons-material/LooksOne'
import LooksTwoIcon from '@mui/icons-material/LooksTwo'
import { CheckoutContext, UserContext } from '../App'
import { useNavigate } from 'react-router'
import { createCustomer, setupIntent } from '../payment/payment-util'

function Subscription({
	scrollTo,
}: {
	scrollTo: React.MutableRefObject<HTMLDivElement | null>
}) {
	const [firstName, setFirstName] = React.useState<string>('')
	const [lastName, setLastName] = React.useState<string>('')
	const [email, setEmail] = React.useState<string>('')
	const [noValidEmail, setNoValidEmail] = React.useState<boolean>(false)
	const { setUserFirstName, setUserLastName, setUserEmail } =
		React.useContext(UserContext)
	const { setCustomerId, setClientSecret } = React.useContext(CheckoutContext)
	const navigate = useNavigate()
	const [error, setError] = React.useState<string>()
	const [isFetching, setIsFetching] = React.useState<boolean>(false)

	const [startSubscribe, { loading: mutationLoading }] = useMutation(
		startSubscribeGql,
		{
			onCompleted: (res) => {
				if (res.startSubscribe.success) {
					navigate('/desktop-version-offer')
				}
			},
			onError: (e) => {
				setError(e.message)
			},
		}
	)

	function onInputChange(e: HTMLInputElement, field: keyof UserInfoFields) {
		switch (field) {
			case 'firstName':
				setFirstName(e.value.trim())
				break
			case 'lastName':
				setLastName(e.value.trim())
				break
			default:
				if (e.value.match(isValidEmail)) {
					setNoValidEmail(false)
				} else {
					setNoValidEmail(true)
				}
				setEmail(e.value.trim())
				break
		}
	}

	async function createCustomerThenSetupIntent() {
		setIsFetching(true)
		try {
			const { id } = await createCustomer(
				firstName,
				lastName,
				email?.trim().toLowerCase()
			)
			const { client_secret } = await setupIntent(id)
			setCustomerId(id)
			setClientSecret(client_secret)
		} catch (e: any) {
			setError(`Error while fetching endpoints: ${e.message}`)
		} finally {
			setIsFetching(false)
		}
	}

	async function onClaimOfferClick() {
		const lowerCaseEmail = email?.trim().toLowerCase()
		await createCustomerThenSetupIntent()
		await startSubscribe({
			variables: {
				// should add first and last name here
				email: lowerCaseEmail,
			},
		})
		setUserFirstName(firstName)
		setUserLastName(lastName)
		setUserEmail(lowerCaseEmail)
		// send an email FREE mobile version. Fire and forget
		fetch(`${process.env.REACT_APP_EXPRESS_SERVER_URL}/send-to-email`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: lowerCaseEmail,
				product: productMapper[Product.MobileVersion],
			}),
		}).catch((e) => {
			setError(`sendToEmail error: ${e.message}.`)
		})
	}

	if (error) {
		throw new Error(error)
	}
	return (
		<div className='subscription-container'>
			<div className='subscription-body'>
				<div className='subscription-body-image'>
					<video
						className='eBook'
						loop
						controls
						autoPlay
						muted
						src={`${process.env.REACT_APP_EXPRESS_SERVER_URL}/video/newMobileRecording`}
					></video>
					{/* <img className='eBook' src={mobile} alt='mobile.png' /> */}
				</div>
				<div className='subscription-body-next'>
					<img
						className='rightArrow'
						src={rightArrow}
						alt='rightArrow'
						height={300}
					/>
				</div>
				<div className='subscription-body-form' ref={scrollTo}>
					<StartSubscribe
						onInputChange={onInputChange}
						onClaimOfferClick={onClaimOfferClick}
						loading={mutationLoading || isFetching}
						firstName={firstName}
						lastName={lastName}
						email={email}
						noValidEmail={noValidEmail}
					/>
				</div>
			</div>
		</div>
	)
}

export default Subscription
