import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Button from '../shared/button'
import { Alert, Snackbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import '../css/startSubscribe.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { UserInfoFields } from '../shared/utils'

function StartSubscribe({
	onInputChange,
	onClaimOfferClick,
	loading,
	firstName,
	lastName,
	email,
	noValidEmail,
}: {
	onInputChange: (e: HTMLInputElement, field: keyof UserInfoFields) => void
	onClaimOfferClick: () => Promise<void>
	loading: boolean
	firstName: string
	lastName: string
	email: string
	noValidEmail: boolean
}) {
	const isButtonDisabled = !firstName || !lastName || !email || noValidEmail
	return (
		<div className='start-subscribe'>
			<div className='form-container'>
				<h1>Instant FREE Access To Code</h1>
				<p>
					<b>Where Should We Ship FREE Mobile Youtube Clone?</b>
				</p>
				<div className='form-control'>
					<div className='name-input'>
						<input
							value={firstName}
							type='text'
							className='firstname-input'
							placeholder='First Name'
							onChange={({ currentTarget }) =>
								onInputChange(currentTarget, 'firstName')
							}
						/>
						{!firstName && <h2 className='required'>Provide First Name</h2>}
						<input
							value={lastName}
							type='text'
							className='lastname-input'
							placeholder='Last Name'
							onChange={({ currentTarget }) =>
								onInputChange(currentTarget, 'lastName')
							}
						/>
						{!lastName && <h2 className='required'>Provide Last Name</h2>}
					</div>
					<div className='email-input'>
						<input
							value={email}
							type='text'
							className='email-input'
							placeholder='Enter e-mail address...'
							onChange={({ currentTarget }) =>
								onInputChange(currentTarget, 'email')
							}
						/>
						{!!email && noValidEmail && (
							<h2 className='required'>Provide a valid email</h2>
						)}
						{!email && <h2 className='required'>Provide an email</h2>}
					</div>
					<div className='button-container'>
						<Button
							className={'claim-button custom-button'}
							onClick={async () => {
								await onClaimOfferClick()
							}}
							loading={loading}
							text={'Claim Offer'}
							icon={
								<ArrowForwardIcon
									style={{
										fontSize: '40px',
										marginRight: '10px',
									}}
								/>
							}
							disabled={isButtonDisabled}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default StartSubscribe
