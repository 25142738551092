import React, { useContext } from 'react'
import WarningIcon from '@mui/icons-material/Warning'
import '../css/desktopVersionSuggestion.css'
// import record from '../assets/images/record.mov'
import { CheckoutContext, UserContext } from '../App'
import { useNavigate } from 'react-router-dom'
import Button from '../shared/button'
import mobile from '../assets/images/mobile4.png'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import { PaymentStatusEnum, Product } from '../shared/utils'
import screenshot from '../assets/images/desktopScreenshot.png'

function DesktopVersionSuggestion() {
	const navigate = useNavigate()
	const {
		paymentStatus,
		setProduct,
		setDesktopSpecialOfferOver,
		setPaymentStatus,
	} = useContext(CheckoutContext)
	const { userEmail, userFirstName, userLastName } =
		React.useContext(UserContext)
	const ref = React.useRef<null | HTMLDivElement>(null)
	const headerRef = React.useRef<null | HTMLDivElement>(null)
	const [minutes, setMinutes] = React.useState(10)
	const [seconds, setSeconds] = React.useState(0)
	const isSpecialOfferOver = minutes === 0 && seconds === 0

	React.useEffect(() => {
		headerRef?.current?.scrollIntoView({ behavior: 'smooth' })
		if (paymentStatus !== PaymentStatusEnum.Undefined) {
			navigate('/')
			setPaymentStatus(PaymentStatusEnum.Undefined)
		}
		// when UserContext gets cleared after refresh
		if (!userEmail || !userFirstName || !userLastName) {
			navigate('/')
		}
	}, [])

	React.useEffect(() => {
		let myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1)
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval)
				} else {
					setMinutes(minutes - 1)
					setSeconds(59)
				}
			}
		}, 1000)
		return () => {
			clearInterval(myInterval)
		}
	})

	return (
		<div className='desktop-version-suggestion-container'>
			<div className='desktop-version-suggestion-header' ref={headerRef}>
				<div>
					<div className='important'>
						<WarningIcon
							color='warning'
							style={{
								width: '70px',
								height: '70px',
							}}
							className='warning'
						/>
						<h1>
							<b>SPECIAL OFFER</b>
						</h1>
						<WarningIcon
							color='warning'
							style={{
								width: '70px',
								height: '70px',
							}}
							className='warning'
						/>
					</div>
					<div className='warning-content'>
						<h1 className='warning-sub'>Great Choice!</h1>
						<h1>
							However, People Ask Me If There Is Also a <u>DESKTOP</u>{' '}
							version...
						</h1>
						<h1 className='unlock-sub'>
							Exclusive Offer! Amazing Price for Future Engineers.
						</h1>
					</div>
				</div>
			</div>
			<div className='video-content'>
				<div className='video-wrapper'>
					<img src={screenshot} alt='screenshot' />
				</div>
				<div className='check-email'>
					<img src={mobile} alt='mobile' />
					<div className='check-email-content' ref={ref}>
						Be sure to check your email including spam... the code should be in
						your inbox within the <b>next few minutes!</b>
					</div>
				</div>
			</div>
			<div className='description-box-wrapper'>
				<div className='description-box-container'>
					<div className='description-box-button-container'>
						<Button
							onClick={() => {
								setDesktopSpecialOfferOver(isSpecialOfferOver)
								setProduct(Product.DesktopVersion)
								navigate('/script-suggestion')
							}}
							text={`Yes! Add "Desktop YouTube Clone" For Just ${
								isSpecialOfferOver ? '$30' : '$10'
							}`}
							loading={false}
							className='custom-button'
						/>
					</div>
					<div className='description-box-content'>
						<div className='description'>
							<div>
								The <u>DESKTOP</u> Version Makes Your Resume <b>STAND OUT</b>,
								Perfect for those Wanting To Showcase their Engineering Skills.
							</div>
							<br />
							<div>To Put it Very Simply,</div>
							<br />
							<div>
								It's a <u>DESKTOP</u> YouTube Clone made Entirely <u>WITHOUT</u>{' '}
								Libraries, A Skill that Recruiters Will Notice.
							</div>
							<br />
							<div>
								Imagine Recruiters Seeing that You Can Clone YouTube{' '}
								<u>WITHOUT</u> Libraries on your Resume.
							</div>
							<br />
							<div>
								This is{' '}
								<u>
									'<b>Just ONE</b> of the Values'
								</u>{' '}
								Included in This Product that recruiters Can Recognize.
							</div>
							<br />
							<div>
								I wish I had time to explain the rest, but Unfortunately!
							</div>
							<br />
							<div>
								Can You Imagine What a{' '}
								<b>
									<u>Recruiter's Reaction</u>
								</b>{' '}
								Might Be If They Saw On Your Resume The{' '}
								<b>
									<u>Ability To Clone</u>
								</b>{' '}
								YouTube <u>WITHOUT</u> Using Libraries?
							</div>
							<br />
							<div>
								Click the button below right now to add the desktop version to
								your order.
							</div>
						</div>
						<button
							className='above-arrow-button'
							onClick={() =>
								ref?.current?.scrollIntoView({ behavior: 'smooth' })
							}
						>
							<KeyboardDoubleArrowUpIcon
								style={{
									margin: 0,
									fontSize: '40px',
									marginRight: '10px',
								}}
							/>
							Yes, Add Now!
						</button>
					</div>
					{isSpecialOfferOver ? (
						<div className='special-offer-end-container'>
							<p>
								<u>Sorry! You Missed The Special Offer...</u>
							</p>
							<h2>
								<span className='was-text'>Was $10</span> Now $30
							</h2>
							<h1>
								This <span className='special-offer-text'>Special Offer</span>{' '}
								Just Ended...
							</h1>
						</div>
					) : (
						<div className='special-offer-container'>
							<p>
								<u>YES! Accept This Amazing Offer Now!</u>
							</p>
							<h2>
								<span className='was-text'>Was $30</span> Just $10
							</h2>
							<h1>
								This <span className='special-offer-text'>Special Offer</span>{' '}
								Ends In...
							</h1>
						</div>
					)}
					<div className='timer-container'>
						<div>
							<span className='number minutes'>
								{minutes < 10 ? `0${minutes}` : minutes}
							</span>
							<span>Minutes</span>
						</div>
						<div>
							<span className='number seconds'>
								{seconds < 10 ? `0${seconds}` : seconds}
							</span>
							<span>Seconds</span>
						</div>
					</div>
					<div className='divider'></div>
					<div className='no-thanks'>
						<h1>
							<u
								onClick={() => {
									setDesktopSpecialOfferOver(isSpecialOfferOver)
									navigate('/thank-you')
								}}
								style={{ cursor: 'pointer' }}
							>
								No Thanks, I Don't Want This Desktop Version At This Amazing
								Price - I'll Pass At This Time
							</u>
						</h1>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DesktopVersionSuggestion
